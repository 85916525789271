import {
  EventType,
  ToggleViewSettingsMenuProperties,
} from "@/analytics/analytics-events";
import { FaroTooltip, ToolButton, ViewOptionsIcon } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { PopoverProps } from "@mui/material";
import { useEffect, useState } from "react";
import { ToolsTooltipText } from "./toolbar";
import { ViewOptionsMenu } from "./view-options/view-options-menu";

export type ViewOptionsToolProps = {
  /** Reference to the element to anchor the popover to */
  anchorEl: PopoverProps["anchorEl"];
  /** Callback executed every time the open state of the menu changes */
  setIsMenuOpen(isOpen: boolean): void;
  /** Reference to the element to anchor badge "new" */
  setButtonRef?: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
};

/** @returns a tool button for the ViewSettingsToolbar to manage the main ViewOptions */
export function ViewOptionsTool({
  anchorEl,
  setIsMenuOpen,
  setButtonRef,
}: ViewOptionsToolProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  function toggleMenu(opening: boolean): void {
    Analytics.track<ToggleViewSettingsMenuProperties>(
      EventType.toggleViewSettingsMenu,
      { opening },
    );

    setIsOpen(opening);
  }

  useEffect(() => {
    setIsMenuOpen(isOpen);
  }, [isOpen, setIsMenuOpen]);

  return (
    <>
      <FaroTooltip
        placement="left"
        title={<ToolsTooltipText text="View settings" />}
      >
        <ToolButton
          onClick={() => toggleMenu(true)}
          selected={isOpen}
          ref={setButtonRef}
        >
          <ViewOptionsIcon />
        </ToolButton>
      </FaroTooltip>

      <ViewOptionsMenu
        open={isOpen}
        onClose={() => toggleMenu(false)}
        anchorEl={anchorEl}
      />
    </>
  );
}
